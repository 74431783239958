import revive_payload_client_IFpvYc7RBw from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_c47HawfGkA from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dVReXm3crQ from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_UarivfHwvz from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass-embed_7nfzr5ven4equrzfmnfoiq2qge/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import directus_fdYmlw0WUJ from "/app/plugins/directus.ts";
import payload_client_GMVSSCklGh from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6OEkNaKocs from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_pNBKAtoaCr from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SORTrjE13r from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Te3fRTERcs from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.12_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_zs47H06RFp from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tl2meM2ChB from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_2qskui7oyqbnr25p56jzcb7ynm/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_QwlfeSWnCT from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1__jvnooeri2gx5h5xe2sjsejxgky/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_PAzUGdPs98 from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1__jvnooeri2gx5h5xe2sjsejxgky/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_RQdI8MB9z4 from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass-em_sseko3x6n6t7bnid4mthorsljy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Sb32w1XE66 from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass-em_sseko3x6n6t7bnid4mthorsljy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import switch_locale_path_ssr_7SI1MjkT9L from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.12_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_na3cyIaL64 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.12_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import gsap_qE0fizvEy0 from "/app/plugins/gsap.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import sonner_client_l6WBUspFNo from "/app/plugins/sonner.client.ts";
export default [
  revive_payload_client_IFpvYc7RBw,
  unhead_c47HawfGkA,
  router_dVReXm3crQ,
  _0_siteConfig_UarivfHwvz,
  directus_fdYmlw0WUJ,
  payload_client_GMVSSCklGh,
  navigation_repaint_client_6OEkNaKocs,
  check_outdated_build_client_pNBKAtoaCr,
  chunk_reload_client_SORTrjE13r,
  plugin_vue3_Te3fRTERcs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zs47H06RFp,
  plugin_tl2meM2ChB,
  titles_QwlfeSWnCT,
  defaultsWaitI18n_PAzUGdPs98,
  siteConfig_RQdI8MB9z4,
  inferSeoMetaPlugin_Sb32w1XE66,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_7SI1MjkT9L,
  i18n_na3cyIaL64,
  plugin_6Ph1iQzNHk,
  gsap_qE0fizvEy0,
  sentry_client_shVUlIjFLk,
  sonner_client_l6WBUspFNo
]