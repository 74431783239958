import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46pageSlug_93HaTbXNDuqUMeta } from "/app/pages/[...pageSlug].vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91slug_93gxPQZvinbYMeta } from "/app/pages/covers/[slug].vue?macro=true";
import { default as indexYMNAB9v5H7Meta } from "/app/pages/covers/index.vue?macro=true";
import { default as _91slug_93rNUtF0rHPmMeta } from "/app/pages/projects/[slug].vue?macro=true";
import { default as indexdd0y1GP2UcMeta } from "/app/pages/projects/index.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stubxQs6f2Jmm5Meta } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubxQs6f2Jmm5 } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__gjymhuus2keiwmgxv2w5fdu6ai/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "pageSlug___nl",
    path: "/nl/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___fr",
    path: "/fr/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue")
  },
  {
    name: "blog-slug___nl",
    path: "/nl/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog___nl",
    path: "/nl/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "covers-slug___nl",
    path: "/nl/covers/:slug()",
    component: () => import("/app/pages/covers/[slug].vue")
  },
  {
    name: "covers-slug___en",
    path: "/en/covers/:slug()",
    component: () => import("/app/pages/covers/[slug].vue")
  },
  {
    name: "covers-slug___fr",
    path: "/fr/covers/:slug()",
    component: () => import("/app/pages/covers/[slug].vue")
  },
  {
    name: "covers-slug___de",
    path: "/de/covers/:slug()",
    component: () => import("/app/pages/covers/[slug].vue")
  },
  {
    name: "covers-slug___es",
    path: "/es/covers/:slug()",
    component: () => import("/app/pages/covers/[slug].vue")
  },
  {
    name: "covers___nl",
    path: "/nl/covers",
    component: () => import("/app/pages/covers/index.vue")
  },
  {
    name: "covers___en",
    path: "/en/covers",
    component: () => import("/app/pages/covers/index.vue")
  },
  {
    name: "covers___fr",
    path: "/fr/covers",
    component: () => import("/app/pages/covers/index.vue")
  },
  {
    name: "covers___de",
    path: "/de/covers",
    component: () => import("/app/pages/covers/index.vue")
  },
  {
    name: "covers___es",
    path: "/es/covers",
    component: () => import("/app/pages/covers/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "projects-slug___nl",
    path: "/nl/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue")
  },
  {
    name: "projects-slug___en",
    path: "/en/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue")
  },
  {
    name: "projects-slug___fr",
    path: "/fr/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue")
  },
  {
    name: "projects-slug___de",
    path: "/de/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue")
  },
  {
    name: "projects-slug___es",
    path: "/es/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue")
  },
  {
    name: "projects___nl",
    path: "/nl/projects",
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "projects___en",
    path: "/en/projects",
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "projects___fr",
    path: "/fr/projects",
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "projects___de",
    path: "/de/projects",
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "projects___es",
    path: "/es/projects",
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "terms-and-conditions___nl",
    path: "/nl/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___fr",
    path: "/fr/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___de",
    path: "/de/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___es",
    path: "/es/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  },
  {
    name: component_45stubxQs6f2Jmm5Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubxQs6f2Jmm5
  },
  {
    name: component_45stubxQs6f2Jmm5Meta?.name,
    path: "/nl-sitemap.xml",
    component: component_45stubxQs6f2Jmm5
  },
  {
    name: component_45stubxQs6f2Jmm5Meta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubxQs6f2Jmm5
  },
  {
    name: component_45stubxQs6f2Jmm5Meta?.name,
    path: "/fr-sitemap.xml",
    component: component_45stubxQs6f2Jmm5
  },
  {
    name: component_45stubxQs6f2Jmm5Meta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubxQs6f2Jmm5
  },
  {
    name: component_45stubxQs6f2Jmm5Meta?.name,
    path: "/es-sitemap.xml",
    component: component_45stubxQs6f2Jmm5
  }
]